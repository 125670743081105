html{
    color: white;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 14px;
    font-weight: 500;
    
}
body{
    height: 100vh;
    background-color:hsla(229,44%,35%,1);
    background-image:
    radial-gradient(at 51% 90%, hsla(225,37%,58%,1) 0px, transparent 50%),
    radial-gradient(at 1% 100%, hsla(217,58%,28%,1) 0px, transparent 50%),
    radial-gradient(at 30% 54%, hsla(234,50%,55%,1) 0px, transparent 50%),
    radial-gradient(circle at 39% 66%, hsla(283,39%,56%,0.76) 0px, transparent 50%);
}

.ball{
    width: 100px;
    position: absolute;
    bottom: 15%;
    right: 20%;
  }
  .flex-container  {
    display: flex;
    justify-content: space-between;
    align-items: center;
      
  }
  .ref::before {
   
    
    content: "Maris ";
  }
  .logo {
    margin: 0 0 0 1em;
  }
  .logo, .navigation {
    list-style: none;
    text-transform: uppercase;
}
.logo a, .navigation a {
    text-decoration: none;
    letter-spacing: .45rem;
}

.navigation  {
display: flex;
}

.navigation a {
display: block;
padding: 1em;
color: white;
}

.navigation a:hover {
background: #00ace6;
}
  nav {
   
    width:auto;
    height: auto;
    margin: 0 auto;
    padding: 20px 15px;
   
    
    
    
    padding: 20px 0px;
    margin-bottom: 35px;


  }
 
  a {
    color: rgb(255, 255, 255);
    text-decoration: white double underline;
  }
  
  a:hover {
    color: white;
  }
  
  a.active {
    color: white;
  }
  
  nav > a {
    text-decoration: none;
    margin: 0 15px;
  }
  
  nav > a.active {
    text-decoration: underline;
  }
  .stage {
    border-bottom: 3px solid rgb(68, 68, 68);
    display: flex;
    height: 330px;
    width: 100%;
}
.cv{
    margin: 1rem;
    padding: 1rem 2rem 2rem 2rem;
    color: rgb(36, 35, 35);
    background-color: rgb(245, 242, 238);
}
.line {
    border-top: 1px solid rgb(226, 222, 222);
    display: flex;
    height: 30px;
    width: 50%;
}
.box {
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background-image: url("https://res.cloudinary.com/dihf0edss/image/upload/c_scale,w_100/v1675414117/lodes_atspulgs.0_p615kk.png");
    height: 100px;
    margin: 0 auto 0 auto;
    transform-origin: bottom;
    width: 100px;
}
.bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: linear;
}
@keyframes bounce-1 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-100px); }
    100% { transform: translateY(0); }
}


.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 10px;
    font-weight: 100;
  }



  
  

  @media all and (max-width: 600px) {
    body{
        height: 100vh;
        background-color:hsla(229,44%,35%,1);
        background-image:
        radial-gradient(circle at 51% 80%, hsla(225,37%,58%,1) 0px, transparent 50%),
        radial-gradient(circle at 1% 100%, hsla(217,58%,28%,1) 0px, transparent 50%),
        radial-gradient(circle at 30% 54%, hsla(234,50%,55%,1) 0px, transparent 50%),
        radial-gradient(circle at 39% 66%, hsla(283,39%,56%,0.76) 0px, transparent 50%);
    }
    .ball{
        width: 50px;
        position: absolute;
        bottom: 15%;
        right: 15%;
      }
      .logo {
        margin: 0 0 0 1em;
    }

    .ref::before {
       
       
        content: "M.";
      }
      
      
      
    }
  